@import "libs/ui/themes/default-theme";
@import "theme";

// Angular default styles
html,
body {
  height: 100%;
  background-color: #171718;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

p {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
}
