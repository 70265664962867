@use 'sass:math';

.p-slider .p-slider-handle {
    background: rgb(23, 23, 23);
}

.avatar__buttons-wrapper .p-slider .p-slider-handle {
    background: rgba(54, 55, 57, 1);
}

.p-slider {
    background: $darkGreyHover;
    border: $sliderBorder;
    border-radius: $borderRadius;
    margin-bottom: 7px;

    &.p-slider-horizontal {
        height: 2px;

        .p-slider-handle {
            margin-top: math.div(-1 * $sliderHandleHeight, 2);
            margin-left: math.div(-1 * $sliderHandleWidth, 2);
        }
    }

    &.p-slider-vertical {
        width: $sliderVerticalWidth;

        .p-slider-handle {
            margin-left: math.div(-1 * $sliderHandleWidth, 2);
            margin-bottom: math.div(-1 * $sliderHandleHeight, 2);
        }
    }

    .p-slider-handle {
        height: $sliderHandleHeight;
        width: $sliderHandleWidth;
        border: 2px solid $whiteHover;
        border-radius: $sliderHandleBorderRadius;
        transition: $formElementTransition;

        &:focus-visible {
            border: 2px solid $darkBlueNormal;
        }

        //&:focus {
        //    @include focused();
        //}
    }

    .p-slider-range {
        background: $whiteHover;
    }

    &:not(.p-disabled) {
        .p-slider-handle:hover {
            border: 2px solid $darkBlueNormal;
        }
    }

    &.p-slider-animate {
        &.p-slider-horizontal {
            .p-slider-handle {
                transition: $formElementTransition, left $transitionDuration;
            }

            .p-slider-range {
                transition: width $transitionDuration;
            }
        }

        &.p-slider-vertical {
            .p-slider-handle {
                transition: $formElementTransition, bottom $transitionDuration;
            }

            .p-slider-range {
                transition: height $transitionDuration;
            }
        }
    }
}
