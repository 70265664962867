.p-tooltip {
    .p-tooltip-text {
        //background: $tooltipBg;
        //color: $tooltipTextColor;
        //padding: $tooltipPadding;
        //box-shadow: $inputOverlayShadow;
        //border-radius: $borderRadius;
        background: rgba(31, 50, 93, 1);
        color: rgba(255, 255, 255, 0.84);
        border-radius: 6px;
        padding: 12px;
    }

    &.p-tooltip-right {
        .p-tooltip-arrow {
            //border-right-color: $tooltipBg;
            border-right-color: rgba(31, 50, 93, 1);
        }
    }

    &.p-tooltip-left {
        .p-tooltip-arrow {
            //border-left-color: $tooltipBg;
            border-left-color: rgba(31, 50, 93, 1);
        }
    }

    &.p-tooltip-top {
        .p-tooltip-arrow {
            //border-top-color: $tooltipBg;
            border-top-color: rgba(31, 50, 93, 1);
        }
    }

    &.p-tooltip-bottom {
        .p-tooltip-arrow {
            //border-bottom-color: $tooltipBg;
            border-bottom-color: rgba(31, 50, 93, 1);
        }
    }
}
