.p-fileupload {
    .p-fileupload-buttonbar {
        //background: $panelHeaderBg;
        //padding: $panelHeaderPadding;
        //border: $panelHeaderBorder;
        //color: $panelHeaderTextColor;
        //border-bottom: 0 none;
        //border-top-right-radius: $borderRadius;
        //border-top-left-radius: $borderRadius;

        .p-button {
            margin-right: $inlineSpacing;

            &.p-fileupload-choose.p-focus {
                @include focused();
            }
        }
    }

    .p-fileupload-content {
        background: $panelContentBg;
        padding: $fileUploadContentPadding;
        border: $panelContentBorder;
        color: $panelContentTextColor;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }

    .p-progressbar {
        height: $fileUploadProgressBarHeight;
    }

    .p-fileupload-row {
        > div {
            padding: $tableBodyCellPadding;
        }
    }

    &.p-fileupload-advanced {
        .p-message {
            margin-top: 0;
        }
    }
}

.p-fileupload-choose {
    background: rgb(0, 56, 255);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 8px;

    svg {
        display: none;
    }

    &:not(.p-disabled):hover {
        background: rgb(41, 88, 255);
        color: $buttonTextHoverColor;
    }

    &:not(.p-disabled):active {
        background: rgb(41, 88, 255);
        color: $buttonTextActiveColor;
    }
}
