/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
        50 : #e8e8e9,
        100 : #c6c6c7,
        200 : #a0a1a2,
        300 : #7a7b7c,
        400 : #5e5e60,
        500 : #414244,
        600 : #3b3c3e,
        700 : #323335,
        800 : #2a2b2d,
        900 : #1c1d1f,
        A100 : #74b4f4,
        A200 : #459bf1,
        A400 : #0381ff,
        A700 : #0074e9,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-accent: (
        50 : #e0e7ff,
        100 : #b3c3ff,
        200 : #809cff,
        300 : #4d74ff,
        400 : #2656ff,
        500 : #0038ff,
        600 : #0032ff,
        700 : #002bff,
        800 : #0024ff,
        900 : #0017ff,
        A100 : #ffffff,
        A200 : #f2f3ff,
        A400 : #bfc2ff,
        A700 : #a6aaff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

