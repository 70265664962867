.p-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: $buttonTextColor;
    background: transparent;
    border: none;
    font-size: 14px;
    transition: $formElementTransition;
    border-radius: 8px;
    white-space: nowrap;
    cursor: pointer;

    //&:enabled:hover {
    //    background: $buttonHoverBg;
    //    color: $buttonTextHoverColor;
    //    border-color: $buttonHoverBorderColor;
    //}

    //&:enabled:active {
    //    background: $buttonActiveBg;
    //    color: $buttonTextActiveColor;
    //    border-color: $buttonActiveBorderColor;
    //}

    &.p-button-xs {
        height: 24px;

        &.p-button-xs--icon {
            width: 24px;
            padding: 0;
        }
    }

    &.p-button-s {
        height: 32px;

        &.p-button-s--icon {
            width: 32px;
            padding: 0;
        }
    }

    &.p-button-m {
        height: 40px;

        &.p-button-m--icon {
            width: 40px;
            padding: 0;
        }
    }

    &.p-button-l {
        height: 48px;

        &.p-button-l--icon {
            width: 48px;
            padding: 0;
        }
    }

    &.p-button-transparent {
        color: $whiteNormal;
    }

    &.p-button-max-width {
        width: 100%;
    }

    &.p-button-filled--blue {
        background-color: $darkBlueNormal;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;

        &:hover {
            background: $darkBlueHover;
        }

    }

    &.p-button-filled--gray {
        background-color: rgba(65, 66, 68, 1);
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;

        svg path {
            fill: $whiteNormal;
        }

        .p-button-l--icon {
            width: 48px;
            padding: 0;
        }

        &:hover {
            background: rgba(92, 97, 106, 1);

            svg path {
                fill: $whiteHover;
            }
        }
    }

    &.p-button-outlined {

        //&:enabled:hover {
        //    color: rgba(255, 255, 255, 1);
        //    border: 1px solid rgba(41, 88, 255, 1);
        //}
        //
        //&:enabled:active {
        //    color: rgba(255, 255, 255, 1);
        //    border: $outlinedButtonBorder;
        //}

        &.p-button-plain {
            color: $plainButtonTextColor;
            border-color: $plainButtonTextColor;

            &:enabled:hover {
                background: $plainButtonHoverBgColor;
                color: $plainButtonTextColor;
            }

            &:enabled:active {
                background: $plainButtonActiveBgColor;
                color: $plainButtonTextColor;
            }
        }
    }

    &.p-button-outlined--blue  {
        color: $whiteHover;
        border: 1px solid $darkBlueNormal;
        background: $lightBlueNormal;
        padding-left: 40px;
        padding-right: 40px;

        &:enabled:hover, &:enabled:active  {
            color: $whiteHover;
            border: 1px solid $darkBlueHover;
        }
    }

    &.p-button-outlined--white {
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: rgba(134, 141, 150, 1);
        background: rgba(147, 155, 164, 0.12);
        border: 2px solid rgba(134, 141, 150, 1);
        border-radius: 8px;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;

        svg path {
            fill: $greyNormal;
            transition: .3s;
        }

        &:enabled:hover, &:enabled:active  {
            color: $whiteHover;
            background: rgba(147, 155, 164, 0.12);
            border: 2px solid rgba(167, 176, 185, 1);

            svg path {
                fill: $whiteHover;
            }
        }
    }

    &.p-button-outlined--red {
        color: rgba(218, 81, 81, 1);
        background: rgba(218, 81, 81, 0.12);
        border: 1px solid rgba(218, 81, 81, 1);

        &:enabled:hover, &:enabled:active  {
        }
    }

    &.p-button-outlined--gray  {
        color: rgba(134, 141, 150, 1);
        border: 1px solid rgba(134, 141, 150, 1);
        background: rgba(147, 155, 164, 0.12);
        padding-left: 40px;
        padding-right: 40px;

        &:enabled:hover, &:enabled:active  {
            color: $whiteNormal;
            border: 1px solid rgba(167, 176, 185, 1);
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $buttonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba($buttonBg, $textButtonHoverBgOpacity);
            color: $buttonBg;
            border-color: transparent;
        }

        &:enabled:active {
            background: rgba($buttonBg, $textButtonActiveBgOpacity);
            color: $buttonBg;
            border-color: transparent;
        }

        &.p-button-plain {
            color: $plainButtonTextColor;

            &:enabled:hover {
                background: $plainButtonHoverBgColor;
                color: $plainButtonTextColor;
            }

            &:enabled:active {
                background: $plainButtonActiveBgColor;
                color: $plainButtonTextColor;
            }
        }
    }

    //&:focus {
    //    @include focused();
    //}

    .p-button-label {
        transition-duration: $transitionDuration;
    }

    .p-button-icon-left {
         margin-right: $inlineSpacing;
    }

    .p-button-icon-right {
        margin-left: $inlineSpacing;
    }

    .p-button-icon-bottom {
        margin-top: $inlineSpacing;
    }

    .p-button-icon-top {
       margin-bottom: $inlineSpacing;
    }

    .p-badge {
        margin-left: $inlineSpacing;
        min-width: $fontSize;
        height: $fontSize;
        line-height: $fontSize;
        color: $buttonBg;
        background-color: $buttonTextColor;
    }

    &.p-button-raised {
        box-shadow: $raisedButtonShadow;
    }

    &.p-button-rounded {
        border-radius: $roundedButtonBorderRadius;
    }

    &.p-button-icon-only {
        width: $buttonIconOnlyWidth;
        padding: $buttonIconOnlyPadding;

        .p-button-icon-left,
        .p-button-icon-right {
            margin: 0;
        }

        &.p-button-rounded {
            border-radius: 50%;
            height: $buttonIconOnlyWidth;
        }
    }

    &.p-button-sm {
        @include scaledFontSize($fontSize, $scaleSM);
        @include scaledPadding($buttonPadding, $scaleSM);

        .p-button-icon {
            @include scaledFontSize($primeIconFontSize, $scaleSM);
        }
    }

    &.p-button-lg {
        @include scaledFontSize($fontSize, $scaleLG);
        @include scaledPadding($buttonPadding, $scaleLG);

        .p-button-icon {
            @include scaledFontSize($primeIconFontSize, $scaleLG);
        }
    }

    &.p-button-loading-label-only {
        .p-button-label {
            margin-left: $inlineSpacing;
        }

        .p-button-loading-icon {
            margin-right: 0;
        }
    }
}

.p-fluid {
    .p-button {
        width: 100%;
    }

    .p-button-icon-only {
        width: $buttonIconOnlyWidth;
    }

    .p-buttonset {
        display: flex;

        .p-button {
            flex: 1;
        }
    }
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    color: $secondaryButtonTextColor;
    background: $secondaryButtonBg;
    border: $secondaryButtonBorder;

    &:enabled:hover {
        background: $secondaryButtonHoverBg;
        color: $secondaryButtonTextHoverColor;
        border-color: $secondaryButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: $secondaryButtonFocusShadow;
    }

    &:enabled:active {
        background: $secondaryButtonActiveBg;
        color: $secondaryButtonTextActiveColor;
        border-color: $secondaryButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $secondaryButtonBg;
        border: $outlinedButtonBorder;

        &:enabled:hover {
            background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
            color: $secondaryButtonBg;
            border: $outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
            color: $secondaryButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $secondaryButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $secondaryButtonBg;
        }

        &:enabled:active {
            background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $secondaryButtonBg;
        }
    }
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
    color: $infoButtonTextColor;
    background: $infoButtonBg;
    border: $infoButtonBorder;

    &:enabled:hover {
        background: $infoButtonHoverBg;
        color: $infoButtonTextHoverColor;
        border-color: $infoButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: $infoButtonFocusShadow;
    }

    &:enabled:active {
        background: $infoButtonActiveBg;
        color: $infoButtonTextActiveColor;
        border-color: $infoButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $infoButtonBg;
        border: $outlinedButtonBorder;

        &:enabled:hover {
            background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
            color: $infoButtonBg;
            border: $outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
            color: $infoButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $infoButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $infoButtonBg;
        }

        &:enabled:active {
            background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $infoButtonBg;
        }
    }
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
    color: $successButtonTextColor;
    background: $successButtonBg;
    border: $successButtonBorder;

    &:enabled:hover {
        background: $successButtonHoverBg;
        color: $successButtonTextHoverColor;
        border-color: $successButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: $successButtonFocusShadow;
    }

    &:enabled:active {
        background: $successButtonActiveBg;
        color: $successButtonTextActiveColor;
        border-color: $successButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $successButtonBg;
        border: $outlinedButtonBorder;

        &:enabled:hover {
            background: rgba($successButtonBg, $textButtonHoverBgOpacity);
            color: $successButtonBg;
            border: $outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba($successButtonBg, $textButtonActiveBgOpacity);
            color: $successButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $successButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba($successButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $successButtonBg;
        }

        &:enabled:active {
            background: rgba($successButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $successButtonBg;
        }
    }
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
    color: $warningButtonTextColor;
    background: $warningButtonBg;
    border: $warningButtonBorder;

    &:enabled:hover {
        background: $warningButtonHoverBg;
        color: $warningButtonTextHoverColor;
        border-color: $warningButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: $warningButtonFocusShadow;
    }

    &:enabled:active {
        background: $warningButtonActiveBg;
        color: $warningButtonTextActiveColor;
        border-color: $warningButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $warningButtonBg;
        border: $outlinedButtonBorder;

        &:enabled:hover {
            background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
            color: $warningButtonBg;
            border: $outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
            color: $warningButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $warningButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $warningButtonBg;
        }

        &:enabled:active {
            background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $warningButtonBg;
        }
    }
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
    color: $helpButtonTextColor;
    background: $helpButtonBg;
    border: $helpButtonBorder;

    &:enabled:hover {
        background: $helpButtonHoverBg;
        color: $helpButtonTextHoverColor;
        border-color: $helpButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: $helpButtonFocusShadow;
    }

    &:enabled:active {
        background: $helpButtonActiveBg;
        color: $helpButtonTextActiveColor;
        border-color: $helpButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $helpButtonBg;
        border: $outlinedButtonBorder;

        &:enabled:hover {
            background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
            color: $helpButtonBg;
            border: $outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
            color: $helpButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $helpButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $helpButtonBg;
        }

        &:enabled:active {
            background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $helpButtonBg;
        }
    }
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
    color: $dangerButtonTextColor;
    background: $dangerButtonBg;
    border: $dangerButtonBorder;

    &:enabled:hover {
        background: $dangerButtonHoverBg;
        color: $dangerButtonTextHoverColor;
        border-color: $dangerButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: $dangerButtonFocusShadow;
    }

    &:enabled:active {
        background: $dangerButtonActiveBg;
        color: $dangerButtonTextActiveColor;
        border-color: $dangerButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: $dangerButtonBg;
        border: $outlinedButtonBorder;

        &:enabled:hover {
            background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
            color: $dangerButtonBg;
            border: $outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
            color: $dangerButtonBg;
            border: $outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $dangerButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $dangerButtonBg;
        }

        &:enabled:active {
            background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $dangerButtonBg;
        }
    }
}

.p-button.p-button-link {
    display: block;
    background: transparent;
    border: transparent;
    padding: 12px;

    &.p-button-link--grey {
        color: rgba(134, 141, 150, 1);
    }

    &.p-button-link--white {
        color: $whiteHover;
    }

    &.p-button-link--violet {
        color: rgba(101, 126, 255, 1);

        &:enabled:hover {
            background: transparent;
            color: rgba(101, 126, 255, 1);
        }
    }

    &:enabled:hover {
        background:  rgba(92, 97, 106, 1);
        color: $whiteHover;
        border-color:  transparent;

        .p-button-label {
            text-decoration: $linkButtonTextHoverDecoration;
        }

        svg path {
            fill: $whiteHover;
        }
    }

    &:enabled:focus {
        border: none;
        //background: transparent;
        //box-shadow: $linkButtonFocusShadow;
        //border-color:  transparent;
    }

    //&:enabled:active {
    //    background:  transparent;
    //    color: $linkButtonColor;
    //    border-color:  transparent;
    //}
}

.content__heading-actions-button .p-button.p-button-link:enabled:hover {
    box-shadow: 0 0 0 2px rgba(167, 176, 185, 1) inset;
}
