.mat-dialog-container {
  background-color: #363739;
}

.mat-dialog-container {
  padding: 0 !important;
}

a:-webkit-any-link:hover, .translate-link:hover {
  cursor: pointer;
  text-decoration: underline dashed #657EFF;
  color: #657EFF;
}

a:-webkit-any-link, .translate-link {
  color: #657EFF;
  text-decoration: unset;
}
