@use 'sass:math';

.p-chip {

    &--blue {
        background: $darkBlueNormal;
        font-size: 12px;
        color: $whiteNormal;
        text-transform: uppercase;
    }

    &--grey {
        background: transparent;
        color: rgba(167, 176, 185, 1);
        font-size: 12px;
        border: 1px solid rgba(167, 176, 185, 1);
    }

    &--green {
        background: transparent;
        color: rgba(47, 174, 2, 1);
        font-size: 14px;
        border: 1px solid rgba(47, 174, 2, 1);
    }

    &--yellow {
        background: transparent;
        color: rgba(233, 169, 6, 1);
        font-size: 14px;
        border: 1px solid rgba(233, 169, 6, 1);
    }

    //background-color: $chipBg;
    //color: $chipTextColor;
    //border-radius: $chipBorderRadius;
    //padding: 0 nth($inputPadding, 2);
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 12px;
    border-radius: 43px;

    .p-chip-text {
        line-height: 1.3;
        //line-height: 1.5;
        //margin-top: math.div(nth($inputPadding, 1), 2);
        //margin-bottom: math.div(nth($inputPadding, 1), 2);
    }

    .p-chip-icon {
        margin-right: $inlineSpacing;
    }

    .pi-chip-remove-icon {
        margin-left: $inlineSpacing;
    }

    img {
        width: 1.5 + nth($inputPadding, 1);
        height: 1.5 + nth($inputPadding, 1);
        margin-left: -1 * nth($inputPadding, 2);
        margin-right: $inlineSpacing;
    }

    .pi-chip-remove-icon {
        border-radius: $borderRadius;
        transition: $actionIconTransition;
        
        &:focus {
            @include focused();
        }
    }
}