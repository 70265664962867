@font-face {
  font-family: "Century-Gothic";
  font-style: normal;
  font-weight: 400;
  src: local("Century-Gothic"),
  url("/assets/fonts/Century-Gothic.woff2"),
  url("/assets/fonts/Century-Gothic.woff");
}

@font-face {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat-Regular"),
  url("/assets/fonts/Montserrat-Regular.woff2"),
  url("/assets/fonts/Montserrat-Regular.woff");
}

@font-face {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto-Regular"),
  url("/assets/fonts/Roboto-Regular.woff2"),
  url("/assets/fonts/Roboto-Regular.woff");
}

@font-face {
  font-family: "Roboto-Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto-Medium"),
  url("/assets/fonts/Roboto-Medium.woff2"),
  url("/assets/fonts/Roboto-Medium.woff");
}

