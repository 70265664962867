// Theme Specific Variables
$primaryColor: #2196F3;
$primaryDarkColor: #1E88E5;
$primaryDarkerColor: #1976D2;
$primaryTextColor: #ffffff;

$colors: (
    "blue": #2196F3,
    "green": #4caf50,
    "yellow": #FBC02D,
    "cyan": #00BCD4,
    "pink": #E91E63,
    "indigo": #3F51B5,
    "teal": #009688,
    "orange": #F57C00,
    "bluegray": #607D8B,
    "purple": #9C27B0,
    "red": #FF4032,
    "primary": $primaryColor
);

// Mandatory Designer Variables
@import 'variables/general';
@import 'variables/form';
@import 'variables/button';
@import 'variables/panel';
@import 'variables/data';
@import 'variables/overlay';
@import 'variables/message';
@import 'variables/menu';
@import 'variables/media';
@import 'variables/misc';

:root {
    --surface-a:#ffffff;
    --surface-b:#f8f9fa;
    --surface-c:#e9ecef;
    --surface-d:#dee2e6;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#495057;
    --text-color-secondary:#6c757d;
    --primary-color:#2196F3;
    --primary-color-text:#ffffff;
    --font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#f8f9fa;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#dee2e6;
    --surface-hover:#e9ecef;
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    --focus-ring: #{$focusShadow};
    color-scheme: light;
}

$darkBlueNormal: rgba(0, 56, 255, 1);
$darkBlueHover: rgba(41, 88, 255, 1);
$darkBlueDisabled: rgba(0, 56, 255, 0.24);
$lightBlueNormal: rgba(0, 56, 255, 0.12);
$lightBlueHover: rgba(41, 88, 255, 0.12);
$whiteNormal: rgba(255, 255, 255, 0.84);
$whiteHover: rgba(255, 255, 255, 1);
$whiteDisabled: rgba(255, 255, 255, 0.24);
$lightGreyNormal: rgba(147, 155, 164, 0.12);
$lightGreyHover: rgba(167, 176, 185, 0.12);
$greyNormal: rgba(134, 141, 150, 1);
$greyHover: rgba(167, 176, 185, 1);
$darkGreyNormal: rgba(65, 66, 68, 1);
$darkGreyHover: rgba(92, 97, 106, 1);
$blackNormal: rgba(46, 48, 53, 1);


$pageColor1: rgba(23, 23, 23, 1);
$pageColor2: rgba(29, 30, 32, 1);
$pageColor3: rgba(46, 48, 53, 1);
$pageColor4: rgba(54, 55, 57, 1);
$pageColor5: rgba(67, 69, 73, 1);
$pageColor6: rgba(80, 80, 80, 1);
$pageColor7: rgba(47, 174, 2, 1);
$pageColor8: rgba(233, 6, 20, 1);
$pageColor9: rgba(233, 169, 6, 1);

$linksColor: rgba(101, 126, 255, 1);
$toastsColor: rgba(31, 50, 93, 1);
