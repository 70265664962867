@use '@angular/material' as mat;

// тема заглушка, т.к. многие компоненты материала не кастомизированы
@import '@angular/material/prebuilt-themes/pink-bluegrey.css';

// custom theme
@import 'default-themes-palettes';
// element customisation for this theme
@import 'elements-customisation';
@import 'scrollbar';

@include mat.core();

// Define a dark theme
$dark-theme: mat.define-dark-theme((
        color: (
                primary: mat.define-palette($md-primary),
                accent: mat.define-palette($md-accent),
        ),
  // Only include `typography` and `density` in the default dark theme.
        typography: mat.define-typography-config(),
        density: 0,
));

/*
// Define a light theme
$light-theme: mat.define-light-theme((
        color: (
                primary: mat.define-palette(mat.$indigo-palette),
                accent: mat.define-palette(mat.$pink-palette),
        ),
));
*/

// Apply the dark theme by default
@include mat.core-theme($dark-theme);
@include mat.button-theme($dark-theme);
@include mat.expansion-theme($dark-theme)

/*
// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: light) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  @include mat.core-color($light-theme);
  @include mat.button-color($light-theme);
}
*/


