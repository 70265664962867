.p-toast {
    opacity: $toastOpacity;

    .p-toast-message {
        margin: $toastMargin;
        box-shadow: $toastShadow;
        border-radius: $borderRadius;

        .p-toast-message-content {
            padding: $toastPadding;
            border-width: $toastBorderWidth;

            .p-toast-message-text {
                margin: $toastMessageTextMargin;
            }

            .p-toast-message-icon {
                font-size: $toastIconFontSize;
            }

            .p-icon:not(.p-toast-icon-close-icon) {
                width: $toastIconFontSize;
                height: $toastIconFontSize;
            }

            .p-toast-summary {
                //font-weight: $toastTitleFontWeight;
                color: $whiteHover;
                font-size: 16px;
            }

            .p-toast-detail {
                margin: $toastDetailMargin;
                font-size: 14px;
            }
        }

        .p-toast-icon-close {
            width: $toastIconFontSize;
            height: $toastIconFontSize;
            border-radius: $actionIconBorderRadius;
            background: transparent;
            transition: $actionIconTransition;

            &:hover {
                background: rgba(255,255,255,.3);
            }

            &:focus {
                @include focused();
            }
        }

        &.p-toast-message-info {
            background: $infoMessageBg;
            border: $infoMessageBorder;
            border-width: $messageBorderWidth;
            color: $infoMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: $infoMessageIconColor;
            }
        }

        &.p-toast-message-success {
            position: relative;
            background: rgba(31, 50, 93, 1);
            //border: $successMessageBorder;
            //border-width: $messageBorderWidth;
            color: $whiteNormal;
            border-radius: 6px;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 4px;
                background: rgba(47, 174, 2, 1);
            }

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: $successMessageIconColor;
            }
        }

        &.p-toast-message-warn {
            background: $warningMessageBg;
            border: $warningMessageBorder;
            border-width: $messageBorderWidth;
            color: $warningMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: $warningMessageIconColor;
            }
        }

        &.p-toast-message-error {
            background: $errorMessageBg;
            border: $errorMessageBorder;
            border-width: $messageBorderWidth;
            color: $errorMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: $errorMessageIconColor;
            }
        }

    }
}
